import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { SIGN_UP_CUSTOMER } from 'src/Graphql/Mutations'
import { useMutation, useQuery, gql, useLazyQuery } from '@apollo/client'
import {
  ErrorNotification,
  SuccessNotification
} from 'src/components/Notification'
import '../MobileStyles.css'
import '../auth.css'
import { Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'


const GET_COUNTRIES = gql`
  query getCountries {
    countries {
      id
      name
    }
  }
`

const GET_CUSTOMER_BY_EMAIL = gql`
query getCustomerByEmail($email: jsonb) {
  customers(where: {emails: {_eq: $email}}) {
    id
  }
}
`

const CustomerSignUpV2 = () => {

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const { data: CountriesData } = useQuery(GET_COUNTRIES)
  const [getCustomerByEmail] = useLazyQuery(GET_CUSTOMER_BY_EMAIL, {
    onCompleted: data => {
      if(data.customers?.length > 0){
        ErrorNotification("Sorry email belongs to existing user");
      }else {
        signUpCustomer();
      }
    }
  })

  const checkUser = (email) => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/check-user?email=${email}`)
    .then(response => {

      // console.log(response.data.data)

      if(response.data.data === email){
        getCustomerByEmail({variables:{
          email: [email]
        }})
      }else{
        createToken()
      }

    })
  }

  const confirmSubmission = () => {
    Swal.fire({
      title: 'Are you sure you want to submit this request?',
      text: "You won't be able to revert this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, accept',
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
          checkUser(formik.values.email)
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      dob: '',
      id_no: '',
      gender: '',
      phone: '',
      email: '',
      country: '',
      digital_address: '',
      password: '',
      confirmPassword: ''
      // companyName: '',
      // contactPersonFirstName: '',
      // contactPersonLastName: '',
      // registrationNumber: '',
      // registrationDoc: null,
    },

    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('Kindly provide your first name')
        .nullable(),
      dob: Yup.date().required('Kindly provide your first name').nullable(),
      gender: Yup.string().required('Kindly select a gender'),
      id_no: Yup.string()
        .required('ID number is required')
        .matches(/^[A-Z]{3}-[0-9]{9}-[0-9]{1}$/, 'Invalid ID number'),
      country: Yup.string().required('Select a country'),
      digital_address: Yup.string()
        .matches(
          /^[A-Z]{2}-[0-9]{3,4}-[0-9]{4}$/,
          'Please enter a valid digital'
        )
        .required('Kindly Provide your digital address'),
      lastName: Yup.string()
        .required('Kindly provide your last name')
        .nullable(),
      email: Yup.string()
        .email('Enter valid email')
        .required('Kindly provide your email'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/,
          'Should contain at least one Upper Case, one Lower Case, one number and a special character'
        )
        .required('Kindly provide a password'),
      confirmPassword: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/,
          'Should contain at least one Upper Case, one Lower Case, one number and a special character'
        )
        .required('Kindly confirm your password'),
      phone: Yup.string()
        .max(10, 'Must be 10 numbers or less')
        .matches(/[0-9]{3}[0-9]{3}[0-9]{4}/)
        .required('Kindly provide your phone number')
    }),

    onSubmit: values => {
      // console.log(values)
      // console.log(formik.values.userType)
      confirmSubmission()
    }
  })

  const [signUpCustomer, { signUpCustomerError, signUpCustomerLoading }] =
    useMutation(SIGN_UP_CUSTOMER, {
      variables: {
        first_name: formik.values.firstName,
        last_name: formik.values.lastName,
        other_names: formik.values.middleName ? formik.values.middleName : '',
        emails: [formik.values.email],
        phone_numbers: [formik.values.phone],
        dob: formik.values.dob,
        gender: formik.values.gender,
        id_no: formik.values.id_no,
        country: formik.values.country,
        digital_address: formik.values.digital_address,
        created_by: 'self'
      },
      onCompleted: data => {
        SuccessNotification('Sign Up was successfull')
        navigate('/')
      },
      onError: error => {
        ErrorNotification('Sign up failed')
        console.log(error)
      }
    })

  const createToken = () => {
    if (formik.values.password === formik.values.confirmPassword) {
      var data = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        audience: process.env.REACT_APP_AUDIENCE,
        grant_type: 'client_credentials'
      }

      setLoading(true)
      var config = {
        method: 'post',
        url: process.env.REACT_APP_AUTH0_URL,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }

      axios(config)
        .then(function (response) {
          const token = response.data.access_token
          signup0(token)
        })
        .catch(function (error) {
          console.log(error)
          setLoading(false)
        })
    } else {
      ErrorNotification('Passwords do not match')
    }
  }

  const signup0 = async token => {
    var data = JSON.stringify({
      connection: process.env.REACT_APP_AUTH0_CONNECTION_TYPE,
      email: formik.values.email,
      password: formik.values.password
    })

    var config = {
      method: 'post',
      url: process.env.REACT_APP_AUTH0_USERS_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    }

    axios(config)
      .then(function (response) {
        setLoading(false)
        // SubmitMotorPolicyRequest()
        // console.log(response)
        if (response.data.user_id && response.status === 201) {
          signUpCustomer()
        }
      })
      .catch(function (error) {
        setLoading(false)
        // console.log(error.response)
        ErrorNotification('Sign Up failed')
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        justifyContent: 'center'
      }}
    >
      <div className='form-heading-container'>
        <h1 className='sign-up-title'>Customer Sign Up</h1>
      </div>

      <Form onSubmit={formik.handleSubmit}>
        <div className='form-class'>
          <Form.Group className='form-item'>
            <Form.Label className='font-loader'>
              First Name<span className='star'>*</span>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type='text'
              class='form-control'
              name='firstName'
              id='firstName'
              style={{
                borderColor:
                  formik.touched['firstName'] && formik.errors['firstName']
                    ? 'red'
                    : null
              }}
            />
            {formik.touched['firstName'] && formik.errors['firstName'] ? (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors['firstName']}
              </p>
            ) : null}
          </Form.Group>

          <Form.Group className='form-item'>
            <Form.Label className='font-loader'>Middle Name</Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type='text'
              class='form-control'
              name='middleName'
              id='middleName'
            />
          </Form.Group>

          <Form.Group className='form-item'>
            <Form.Label className='font-loader'>
              Last Name<span className='star'>*</span>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type='text'
              class='form-control'
              name='lastName'
              id='lastName'
              style={{
                borderColor:
                  formik.touched['lastName'] && formik.errors['lastName']
                    ? 'red'
                    : null
              }}
            />
            {formik.touched['lastName'] && formik.errors['lastName'] ? (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors['lastName']}
              </p>
            ) : null}
          </Form.Group>
          <Form.Group className='form-item'>
            <Form.Label className='font-loader'>
              Gender<span className='star'>*</span>
            </Form.Label>
            <select
              class='form-control col-md-12'
              style={{ textAlign: 'left' }}
              onChange={formik.handleChange}
              name='gender'
            >
              <option value={null}>Select gender</option>
              <option value={'Male'}>Male</option>
              <option value={'Female'}>Female</option>
            </select>

            {formik.touched['gender'] && formik.errors['gender'] ? (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors['gender']}
              </p>
            ) : null}
          </Form.Group>
          <Form.Group className='form-item'>
            <Form.Label className='font-loader'>
              Date of birth<span className='star'>*</span>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type='date'
              class='form-control'
              name='dob'
              id='dob'
              style={{
                borderColor:
                  formik.touched['dob'] && formik.errors['dob'] ? 'red' : null
              }}
            />
            {formik.touched['dob'] && formik.errors['dob'] ? (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors['dob']}
              </p>
            ) : null}
          </Form.Group>
          <Form.Group className='form-item'>
            <Form.Label className='font-loader'>
              Country<span className='star'>*</span>
            </Form.Label>
            <select
              class='form-control col-md-12'
              style={{ textAlign: 'left' }}
              onChange={formik.handleChange}
              name='country'
            >
              <option value={null}>Select country of origin</option>
              {CountriesData?.countries?.map(country => (
                <option value={country.name}>{country.name}</option>
              ))}
            </select>

            {formik.touched['country'] && formik.errors['country'] ? (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors['country']}
              </p>
            ) : null}
          </Form.Group>
          <Form.Group className='form-item'>
            <Form.Label className='font-loader'>
              ID Number<span className='star'>*</span>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type='text'
              class='form-control'
              name='id_no'
              id='id_no'
              style={{
                borderColor:
                  formik.touched['id_no'] && formik.errors['id_no']
                    ? 'red'
                    : null
              }}
            />
            {formik.touched['id_no'] && formik.errors['id_no'] ? (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors['id_no']}
              </p>
            ) : null}
          </Form.Group>
          <Form.Group className='form-item'>
            <Form.Label className='font-loader'>
              Digital Address<span className='star'>*</span>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type='text'
              class='form-control'
              name='digital_address'
              id='digital_address'
              style={{
                borderColor:
                  formik.touched['digital_address'] &&
                  formik.errors['digital_address']
                    ? 'red'
                    : null
              }}
            />
            {formik.touched['digital_address'] &&
            formik.errors['digital_address'] ? (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors['digital_address']}
              </p>
            ) : null}
          </Form.Group>
          <Form.Group className='form-item'>
            <Form.Label className='font-loader'>
              Phone Number<span className='star'>*</span>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type='text'
              class='form-control'
              name='phone'
              id='phone'
              style={{
                borderColor:
                  formik.touched['phone'] && formik.errors['phone']
                    ? 'red'
                    : null
              }}
            />
            {formik.touched['phone'] && formik.errors['phone'] ? (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors['phone']}
              </p>
            ) : null}
          </Form.Group>
          <Form.Group className='form-item'>
            <Form.Label className='font-loader'>
              Email<span className='star'>*</span>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type='email'
              class='form-control'
              name='email'
              id='email'
              style={{
                borderColor:
                  formik.touched['email'] && formik.errors['email']
                    ? 'red'
                    : null
              }}
            />
            {formik.touched['email'] && formik.errors['email'] ? (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors['email']}
              </p>
            ) : null}
          </Form.Group>

          <Form.Group className='form-item'>
            <Form.Label className='font-loader'>
              Password<span className='star'>*</span>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type='password'
              class='form-control'
              name='password'
              id='password'
              style={{
                borderColor:
                  formik.touched['password'] && formik.errors['password']
                    ? 'red'
                    : null
              }}
            />
            {formik.touched['password'] && formik.errors['password'] ? (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors['password']}
              </p>
            ) : null}
          </Form.Group>

          <Form.Group className='form-item'>
            <Form.Label className='font-loader'>
              Confirm Password<span className='star'>*</span>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type='password'
              class='form-control'
              name='confirmPassword'
              id='confirmPassword'
              style={{
                borderColor:
                  formik.touched['confirmPassword'] &&
                  formik.errors['confirmPassword']
                    ? 'red'
                    : null
              }}
            />
            {formik.touched['confirmPassword'] &&
            formik.errors['confirmPassword'] ? (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors['confirmPassword']}
              </p>
            ) : null}
          </Form.Group>
        </div>
        {/* {console.log(formik.errors)} */}
        {/* <button className='btn btn-primary font-loader' type='submit'>
          Create Account
        </button> */}
        <div>
          <button
            className='font-loader'
            disabled={signUpCustomerLoading}
            class='btn btn-primary'
          >
            {signUpCustomerLoading ? (
              <div class='spinner-border' role='status'>
                <span className='font-loader' class='sr-only'>
                  Loading...
                </span>
              </div>
            ) : (
              'Create Account'
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default CustomerSignUpV2
