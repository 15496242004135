import React, { useEffect, useState } from 'react';
import WebFont from 'webfontloader';
import { useFormik } from 'formik';
import { formatDate, addDays } from 'src/utils/FormatDate';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';

import FormInput from './FormInput';
import FormOptionsV2 from './FormOptionsV2';
import { useMutation, useQuery, gql, useLazyQuery } from '@apollo/client';
import { update_vehicle_info, Policyinfo, months } from './AllQuestions';
import { isMobile } from 'react-device-detect';
import myPolicyLogo from '../../assets/img/MyPolicy.svg';
import customLoading from '../../assets/img/logo_animation.gif';
import {
  AiOutlineCar,
  AiOutlineArrowLeft,
  AiOutlineBook
} from 'react-icons/ai';
import LinearProgress from '@mui/material/LinearProgress';
import { Form } from 'react-bootstrap';
import {
  ErrorNotification,
  // SuccessNotification
} from 'src/components/Notification';
import Swal from 'sweetalert2';

// const GET_MOTOR_INSURANCE_OPTIONS = gql`
//   query getMotorOptions {
//     request_types(where: { id: { _eq: 1 } }) {
//       properties
//     }
//   }
// `;

const UPDATE_MOTOR_REQUEST_DETAILS = gql`
  mutation updateMotorRequestDetails($requestId: uuid, $motor_details: jsonb) {
    update_requests(
      where: { id: { _eq: $requestId } }
      _set: { motor_details: $motor_details }
    ) {
      affected_rows
      returning {
        motor_details
      }
    }
  }
`;

const UpdateMotorRequest = () => {
  const mysteps = [Policyinfo, update_vehicle_info];

  const lastSet = mysteps[mysteps.length - 1];

  const location = useLocation();
  const requestId = location.state?.requestId;
  const requestDetails = location.state?.details;

  const [period, setPeriod] = useState('yyyy/mm/dd');
  const [progress, setProgress] = useState([0, 0]);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [questionSetIndex, setquestionSetIndex] = useState(0);
  const [insuranceType, setInsuranceType] = useState(location.state?.motorInsuranceType);
  const navigate = useNavigate();

  let currentSectionArray = [];

  // const { data: motorOptions } = useQuery(GET_MOTOR_INSURANCE_OPTIONS);

  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      color: '',
      registration_number: '',
      chassis_number: '',
      // insurance_product_id: '',
      inception_date: '',
      interval: '',
      expiry_date: '',
      buy_back_excess: '',
      repair_state: '',
      increase_thirparty_damage_limit: '',
      altered_vehicle_design: '',
      state_of_claim: '',
      no_claim_years: '',
      usage_type: '',
      seats: '',
      purchase_date: '',
      engine_capacity: '',
      mileage: '',
      // other_usage: null,
      purchase_for_self: '',
      motorInsuranceType: location.state?.motorInsuranceType
    },

    validationSchema: Yup.object({
      // insurance_product_id: Yup.string().required(
      //   'Kindly select an insurance product'
      // ),
      inception_date: Yup.date()
        .min(
          currentDate,
          'Policy Start date must be equal or greater then current date '
        )
        .required('Kindly indicate when you want your insurance to start'),
      color: Yup.string().required('Kindy state the color of your vehicle'),
      registration_number: Yup.string().required(
        'Kindly provide the registration number of your vehicle'
      ),
      chassis_number: Yup.string().required(
        'Kindly provide the chassiss number'
      ),
      interval: Yup.string().required(
        'Kindly indicate how long you want your insurance to last'
      ),

      usage_type: Yup.string().when('motorInsuranceType', {
        is: motorInsuranceType => motorInsuranceType === 'Comprehensive',
        then: Yup.string().required('Kindly select a usage type')
      }),
      buy_back_excess: Yup.string().required(
        'Kindly indicate if you want buy back excess or not'
      ),
      increase_thirparty_damage_limit: Yup.string().when('motorInsuranceType', {
        is: motorInsuranceType => motorInsuranceType === 'Comprehensive',
        then: Yup.string().required('Kindly select a third party damage option')
      }),
      repair_state: Yup.string().when('motorInsuranceType', {
        is: motorInsuranceType => motorInsuranceType === 'Comprehensive',
        then: Yup.string().required(
          'Kindly indicate the repair state of your vehicle'
        )
      }),
      altered_vehicle_design: Yup.string().when('motorInsuranceType', {
        is: motorInsuranceType =>
          Number(motorInsuranceType) === 'Comprehensive',
        then: Yup.string().required(
          'Kindly indicate if your vehicle has been changed from the original design'
        )
      }),
      state_of_claim: Yup.string().when('motorInsuranceType', {
        is: motorInsuranceType => motorInsuranceType === 'Comprehensive',
        then: Yup.string().required(
          'Kindly indicate if you have ever made a claim'
        )
      }),
      no_claim_years: Yup.number().when('state_of_claim', {
        is: state_of_claim => state_of_claim === 'Yes',
        then: Yup.number().required(
          'Kindly indicate the number of years since your last claim'
        )
      }),
      purchase_date: Yup.date().when('motorInsuranceType', {
        is: motorInsuranceType => motorInsuranceType === 'Comprehensive',
        then: Yup.date().required(
          'Kindly indicate the date your vehicle was purchased'
        )
      }),
      engine_capacity: Yup.number().when('motorInsuranceType', {
        is: motorInsuranceType => motorInsuranceType === 'Comprehensive',
        then: Yup.number().required(
          'Kindly indicate the engine capacity of your vehicle'
        )
      }),
      mileage: Yup.number().when('motorInsuranceType', {
        is: motorInsuranceType => motorInsuranceType === 'Comprehensive',
        then: Yup.number().required(
          'Kindly indicate the current mileage of your vehicle'
        )
      })
      // road_worthy_doc: Yup.mixed()
      //   .required('Please upload a copy of your roadworthy document')
      //   .test(
      //     'fileSize',
      //     'File is too large',
      //     value => value && value.size <= FILE_SIZE
      //   )
      //   .test(
      //     'fileFormat',
      //     'Unsupported Format! we only support (jpg, jpeg, png, pdf)',
      //     value => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      //   ),
      // purchase_for_self: Yup.string().required("Kindly indicate who this insurance request is for"),
      // beneficiary_name: Yup.string().when('purchase_for_self', {
      //   is: purchase_for_self => purchase_for_self === 'No',
      //   then: Yup.string().required(
      //     'Kindly provide the name of the beneficiary of the cover'
      //   )
      // }),
      // beneficiary_email: Yup.string()
      //   .email()
      //   .when('purchase_for_self', {
      //     is: purchase_for_self => purchase_for_self === 'No',
      //     then: Yup.string().email('Kindly enter a valid email')
      //   }),
      // beneficiary_phone_number: Yup.string().when('purchase_for_self', {
      //   is: purchase_for_self => purchase_for_self === 'No',
      //   then: Yup.string()
      //     .max(10, 'Must be 10 numbers or less')
      //     .matches(
      //       /[0-9]{3}[0-9]{3}[0-9]{4}/,
      //       'Must contain only digit characters'
      //     )
      //     .required(
      //       'Kindly provide the phone number of the beneficiary of the cover'
      //     )
      // }),
      // beneficiary_id: Yup.string().when('purchase_for_self', {
      //   is: purchase_for_self => purchase_for_self === 'No',
      //   then: Yup.string().required(
      //     'ID number is required of beneficiary or company is required'
      //   )
      //   // .matches(/^[A-Z]{3}-[0-9]{9}-[0-9]{1}$/, 'Invalid ID number')
      // })
    }),

    onSubmit: () => {
      const updatedProgress = [...progress];
      updatedProgress[currentFormIndex] = 100;
      setProgress(updatedProgress);
      confirmSubmission();
      // console.log(values)
    }
  });

  const [updateRequest, { loading, error }] = useMutation(
    UPDATE_MOTOR_REQUEST_DETAILS,
    {
      variables: {
        requestId,
        motor_details: {
          ...requestDetails,
          ...formik.values
        }
      },
      onCompleted: () => {
        navigate('/customer/myrequests');
      }
    }
  );

  const confirmSubmission = () => {
    Swal.fire({
      title: 'Are you sure you want to submit this request?',
      text: "You won't be able to revert this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, accept',
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        updateRequest();
      }
    });
  };

  const vehicleUses = [
    {
      id: 1,
      name: 'The carriage of goods or samples'
    },
    {
      id: 2,
      name: 'The carriage of passengers for hire or reward'
    },
    {
      id: 3,
      name: 'Use in connection with the motor trade'
    },
    {
      id: 4,
      name: 'Private use i.e. Use by yourself only on your own business'
    }
  ];

  const setInceptionDate = e => {
    const value = e.target.value;
    formik.setFieldValue(
      'inception_date',
      new Date(value).toISOString().substring(0, 10)
    );
  };

  const setPurchaseDate = e => {
    const value = e.target.value;
    formik.setFieldValue(
      'purchase_date',
      new Date(value).toISOString().substring(0, 10)
    );
  };

  const onPeriodInput = e => {
    let initialdate = formik.values['inception_date'];

    if (initialdate) {
      // formik.setFieldValue("expiry_date", addMonthV2(initialdate, interval));
      let finaldate = addDays(initialdate, parseInt(e.target.value));
      let formatteddate = formatDate(finaldate);
      formik.setFieldValue('expiry_date', finaldate);

      setPeriod(formatteddate);
    }

    // console.log(formik.values['inception_date'])
  };

  const setOptions = name => {
    if (name === 'usage_type') {
      return vehicleUses;
    } else if (name === 'interval') {
      return months;
    } else if (name === 'model_id') {
      if (formik.values['vehicle_make']) {
        return vehicle_model;
      }
    } else {
      let options = [
        {
          name: 'Yes',
          id: 'Yes',
          value: true
        },
        {
          name: 'No',
          id: 'No',
          value: false
        }
      ];

      return options;
    }
  };

  const stepValidator = () => {
    let validatated = true;
    // console.log("I'm invoked")

    for (let i = 0; i < currentSectionArray.length; i++) {
      if (formik.touched[currentSectionArray[i]] != true) {
        validatated = validatated && false;
        // console.log(currentSectionArray[i] + " Was not touched")
      } else if (formik.errors[currentSectionArray[i]]) {
        validatated = validatated && false;
        // console.log(currentSectionArray[i] + " has errors")
      } else {
        validatated = validatated && true;
        // console.log(currentSectionArray[i] + " is valid")
      }
    }

    if (validatated == false)
      ErrorNotification('Kindly provide all the requested information');

    return validatated;
  };

  const increaseProgress = () => {
    if (progress[currentFormIndex] < 100) {
      if (mysteps[currentFormIndex].length > 1) {
        let section = 100 / (mysteps[currentFormIndex].length - 1);
        const updatedProgress = [...progress];
        updatedProgress[currentFormIndex] =
          section + progress[currentFormIndex];
        setProgress(updatedProgress);
      } else if (mysteps[currentFormIndex].length == 1) {
        const updatedProgress = [...progress];
        updatedProgress[currentFormIndex] = 100;
        setProgress(updatedProgress);
      }
    }
  };

  const reduceProgress = () => {
    if (progress[currentFormIndex < 100]) {
      if (questionSetIndex < mysteps[currentFormIndex].length) {
        let section = 100 / (mysteps[currentFormIndex].length - 1);
        const updatedProgress = [...progress];
        updatedProgress[currentFormIndex] =
          progress[currentFormIndex] - section;
        setProgress(updatedProgress);
      } else if (mysteps[currentFormIndex].length == 1) {
        const updatedProgress = [...progress];
        updatedProgress[currentFormIndex] = 0;
        setProgress(updatedProgress);
      }
    }
  };

  const nextStep = e => {
    e.preventDefault();

    if (stepValidator()) {
      currentSectionArray = [];

      if (insuranceType != null && insuranceType != 'Comprehensive') {
        setCurrentFormIndex(currentFormIndex + 1);
        setquestionSetIndex(0);
        const updatedProgress = [...progress];
        updatedProgress[currentFormIndex] = 100;
        setProgress(updatedProgress);
        setInsuranceType(null);
        // console.log(formik.values)
      } else if (questionSetIndex == mysteps[currentFormIndex].length - 1) {
        setCurrentFormIndex(currentFormIndex + 1);
        // console.log(formik.values)
        setquestionSetIndex(0);
      } else if (
        currentFormIndex == mysteps.length - 1 &&
        questionSetIndex == lastSet.length - 1
      ) {
        increaseProgress();
        // console.log(formik.values)
        completeForm();
      } else {
        setquestionSetIndex(questionSetIndex + 1);
        // console.log(formik.values)
        increaseProgress();
      }
    }
  };
  const prevStep = e => {
    e.preventDefault();
    if (questionSetIndex > 0) {
      setquestionSetIndex(questionSetIndex - 1);
      reduceProgress();
    } else if (questionSetIndex == 0 && currentFormIndex > 0) {
      setCurrentFormIndex(currentFormIndex - 1);
      setquestionSetIndex(mysteps[currentFormIndex].length - 1);
      reduceProgress();
    } else if (questionSetIndex > 0 && currentFormIndex == 0) {
      setquestionSetIndex(questionSetIndex - 1);
      reduceProgress();
    }
  };

  const completeForm = () => {
    // alert('FForm Complete');
    increaseProgress();
  };

  const navigateToForm = formIndex => {
    // //Setting the progress of the current form to 0
    // let updatedProgress = [...progress];
    // updatedProgress[currentFormIndex] = 0;
    // updatedProgress[formIndex] = 0
    // setProgress(updatedProgress);

    //Navigating to the selected Form
    setCurrentFormIndex(formIndex); //Setting the selected form index
    setquestionSetIndex(0);
  };

  const makeActive = (currentFormIndex, id) => {
    if (currentFormIndex == id) return 'active-class';
  };

  const iconSelect = id => {
    if (id == 0)
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center'
          }}
        >
          <AiOutlineBook
            onClick={() => navigateToForm(id)}
            className={makeActive(currentFormIndex, id)}
            size={20}
            style={{ position: 'relative', left: 50 }}
          />
          <span
            style={{
              color: id == currentFormIndex ? 'rgb(46, 121, 233)' : null
            }}
            className="font-loader "
          >
            Policy Information
          </span>
        </div>
      );
    else if (id == 1)
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center'
          }}
        >
          <AiOutlineCar
            onClick={() => navigateToForm(id)}
            className={makeActive(currentFormIndex, id)}
            size={20}
            style={{ position: 'relative', left: isMobile ? 50 : 60 }}
          />
          <span
            style={{
              color: id == currentFormIndex ? 'rgb(46, 121, 233)' : null
            }}
            className="font-loader "
          >
            Vehicle Information
          </span>
        </div>
      );
    // else if (id == 2)
    //   return (
    //     <div
    //       style={{
    //         display: 'flex',
    //         flexDirection: 'column',
    //         textAlign: 'center'
    //       }}
    //     >
    //       <BiUserPlus
    //         onClick={() => navigateToForm(id)}
    //         className={makeActive(currentFormIndex, id)}
    //         size={20}
    //         style={{ position: 'relative', left: isMobile ? 55 : 80 }}
    //       />
    //       <span
    //         style={{
    //           color: id == currentFormIndex ? 'rgb(46, 121, 233)' : null
    //         }}
    //         className='font-loader '
    //       >
    //         Policy Holder Information
    //       </span>
    //     </div>
    //   )
  };

  return !requestId ? (
    <div>
      <img
        style={{
          position: 'relative',
          marginTop: isMobile ? '80%' : '20%',
          left: isMobile ? '35%' : '45%'
        }}
        src={customLoading}
      />
    </div>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <header
          id="header"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch'
          }}
          className="fixed-top"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignSelf: 'stretch',
              width: '100%',
              padding: isMobile ? 0 : 5
            }}
          >
            <div
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                width: '75%'
              }}
            >
              <div
                style={{
                  margin: 15,
                  margin: isMobile ? 5 : 15,
                  left: isMobile ? -35 : 0
                }}
              >
                <a href="/">
                  <img
                    style={{
                      height: isMobile ? '18px' : '28px',
                      width: isMobile ? '129px' : '259.84px'
                    }}
                    src={myPolicyLogo}
                  />
                </a>
              </div>
              <div>
                {isMobile ? (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={e => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      style={{
                        backgroundColor: '#FFFFFF',
                        border: 'none',
                        fontWeight: 'bold'
                      }}
                    >
                      <AiOutlineArrowLeft
                        size={20}
                        style={{ color: '#2E79E9' }}
                      />
                    </button>
                  </>
                ) : (
                  <input
                    className="getstarted font-loader scrollto"
                    onClick={e => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    style={{
                      color: '#F6F8FB',
                      backgroundColor: '#2E79E9',
                      border: 'none',
                      width: '160px',
                      height: '40px',
                      fontSize: '18px'
                    }}
                    type="button"
                    value="Go Back"
                  />
                )}
              </div>
            </div>
          </div>
        </header>
      </div>
      {isMobile ? (
        <div style={{ marginTop: 110 }}>
          <div style={{ textAlign: 'center', position: 'relative', left: 30 }}>
            <div className="icon">{iconSelect(currentFormIndex)}</div>
            <div style={{ position: 'relative', left: 20 }}>
              <LinearProgress
                className="progress-mobile"
                variant="determinate"
                value={progress[currentFormIndex]}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <span
                className="progress-value"
                style={{
                  display: 'block'
                }}
              >
                Step {questionSetIndex + 1}/{mysteps[currentFormIndex].length}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            marginTop: isMobile ? 100 : 200
          }}
          className="progress-icons-container"
        >
          {[0, 1].map((step, key) => {
            return (
              <div key={key} style={{ textAlign: 'center' }}>
                <div
                  style={{
                    cursor: 'pointer'
                  }}
                  className="icon"
                >
                  {iconSelect(step)}
                </div>
                <div>
                  <LinearProgress
                    className="progress"
                    variant="determinate"
                    value={progress[step]}
                  />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <span
                    className="progress-value font-loader"
                    style={{
                      display: currentFormIndex != step ? 'none' : 'block'
                    }}
                  >
                    Step {questionSetIndex + 1}/
                    {mysteps[currentFormIndex].length}
                  </span>
                </div>
              </div>
            );
          })}
          {/* <AiOutlineCar className='active-class' size={20} style={{ marginRight: 25 }} />
                          <LinearProgress style={{ width: 300 }} variant="determinate" value={progress} /> */}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <h3
          style={{ left: isMobile ? 30 : 0 }}
          className="form-header font-loader"
        >
          {mysteps[currentFormIndex][questionSetIndex].title}
        </h3>
        <div style={{ left: isMobile ? 30 : 0 }} className="form-container">
          <Form>
            {mysteps[currentFormIndex][questionSetIndex].questions.map(
              (current, key) => {
                currentSectionArray.push(current.name);
                // console.log(currentSectionArray)
                // console.log(current.name)

                return (
                  <>
                    {current.type === 'select' ? (
                      <FormOptionsV2
                        key={key}
                        type={current.type}
                        name={current.name}
                        label={current.label}
                        handleChange={e => {
                          e.preventDefault();
                          if (current.name === 'vehicle_make') {
                            formik.handleChange(e);
                            onMakeInput(e);
                          } else if (current.name === 'interval') {
                            formik.handleChange(e);
                            onPeriodInput(e);
                          } else {
                            formik.handleChange(e);
                          }
                        }}
                        options={setOptions(current.name)}
                        value={formik.values[current.name]}
                        style={{
                          borderColor:
                            formik.touched[current.name] &&
                            formik.errors[current.name]
                              ? 'red'
                              : null
                        }}
                        onBlur={formik.handleBlur}
                      />
                    ) : current.type === 'file' ? (
                      <FileInput
                        placeholder={current.placeholder}
                        name={current.name}
                        label={current.label}
                        handleChange={handleFile}
                        style={{
                          borderColor:
                            formik.touched[current.name] &&
                            formik.errors[current.name]
                              ? 'red'
                              : null
                        }}
                        onBlur={formik.handleBlur}
                      />
                    ) : (
                      <FormInput
                        key={key}
                        type={current.type}
                        placeholder={current.placeholder}
                        name={current.name}
                        label={current.label}
                        handleChange={
                          current.name == 'inception_date'
                            ? setInceptionDate
                            : current.name === 'setPurchaseDate'
                            ? setPurchaseDate
                            : formik.handleChange
                        }
                        value={formik.values[current.name]}
                        style={{
                          borderColor:
                            formik.touched[current.name] &&
                            formik.errors[current.name]
                              ? 'red'
                              : null
                        }}
                        onBlur={formik.handleBlur}
                      />
                    )}
                    {formik.values['expiry_date'] &&
                    current.name === 'interval' ? (
                      <div
                        style={{
                          marginBottom: 15,
                          marginTop: -12
                        }}
                      >
                        <span
                          style={{
                            position: 'relative',
                            fontSize: 13
                          }}
                        >
                          Your policy will expire on{' '}
                          <span style={{ fontWeight: 'bold' }}>{period}</span>
                        </span>
                      </div>
                    ) : null}
                    {formik.touched[current.name] &&
                    formik.errors[current.name] ? (
                      <p
                        style={{
                          color: 'red',
                          fontSize: 12,
                          marginTop: -8,
                          marginBottom: 8
                        }}
                      >
                        {formik.errors[current.name]}
                      </p>
                    ) : null}
                  </>
                );
              }
            )}
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 25
                }}
              >
                {questionSetIndex != 0 && (
                  <button
                    className="btn btn-primary"
                    style={{ width: '25%' }}
                    onClick={e => prevStep(e)}
                  >
                    Back
                  </button>
                )}
                {(currentFormIndex == mysteps.length - 1 &&
                  questionSetIndex == lastSet.length - 1) ||
                formik.values.purchase_for_self === 'Yes' ? (
                  <button
                    className="btn btn-primary"
                    style={{
                      width:
                        questionSetIndex != 0 && currentFormIndex >= 0
                          ? '65%'
                          : '100%'
                    }}
                    // type='submit'
                    disabled={loading}
                    onClick={e => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                  >
                    {loading ? 'Submitting...' : 'Submit'}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    style={{
                      width:
                        questionSetIndex != 0 && currentFormIndex >= 0
                          ? '65%'
                          : '100%'
                    }}
                    onClick={e => nextStep(e)}
                  >
                    Continue
                  </button>
                )}
              </div>
            </>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default UpdateMotorRequest;
