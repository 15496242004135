import React, { Component, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BiArrowBack, BiDownArrow } from 'react-icons/bi';
import Table from 'react-bootstrap/Table';
import './index.css';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { LOAD_COMPANIES, NEW_REQUEST_NOT_QUOTED } from 'src/Graphql/Queries';
import { formatDate } from 'src/utils/FormatDate';
import { formatCurrency } from 'src/utils/FormatCurrency';
import { useQuery, useMutation, useLazyQuery, gql } from '@apollo/client';
import customLoading from '../../../assets/img/logo_animation.gif';
import { useAppContext } from 'src/Context';
import {
  BROKER_CREATE_QUOTE,
  SEND_NOTIFICATION_CUSTOMER
} from 'src/Graphql/Mutations';
import { getToday } from 'src/utils/FormatDate';
import {
  ErrorNotification,
  SuccessNotification
} from 'src/components/Notification';
import axios from 'axios';
import { convertWildcardStringToRegExp } from 'src/utils/FormatCurrency';
import { GET_ALL_COMPANIES_FOR_INTERMEDIARY } from 'src/Graphql/Queries';
import { Form } from 'react-bootstrap';

import {
  Box,
  Card,
  CardContent,
  Container,
  CardHeader,
  Checkbox,
  colors,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: 500,
    width: 828,
    marginTop: 50,
    borderRadius: 10
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  },
  active: {
    backgroundColor: '#2E79E9',
    height: 82,
    width: 260,
    color: 'white',
    '& $title': {
      marginTop: 5,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 15
    },
    '& $icon': {
      color: 'white',
      width: 22,
      height: 49
    }
  }
}));

const GET_REQUEST_DETAILS = gql`
  query getRequestByRequestID($requestID: uuid) {
    requests(where: { id: { _eq: $requestID } }) {
      motor_details
      home_details
      customer_id
    }
  }
`;

const GET_PROXY_INSURER_COMMISSION = gql`
  query getProxyCommissions($proxyID: uuid, $_in: [uuid]!) {
    proxy_commissions(
      where: {
        proxy_id: { _eq: $proxyID }
        insurance_company_id: { _in: $_in }
      }
    ) {
      broker_commission
      insurance_company_id
    }
  }
`;

const GET_UPLOADED_FILE = gql`
  query getFileByReference($reference: String) {
    files(where: { reference_id: { _eq: $reference } }) {
      id
      key
      type
      reference_id
      properties
    }
  }
`;

const BrokerRequestDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const rowdata = location.state;
  const [file, setFile] = useState(null);
  // console.log(rowdata)

  const { data: requestDetails } = useQuery(GET_REQUEST_DETAILS, {
    variables: {
      requestID: rowdata?.request_id
    },
    onCompleted: data => {
      // console.log(data)
    }
  });

  const {} = useQuery(GET_UPLOADED_FILE, {
    variables: {
      reference: rowdata?.request_id
    },
    onCompleted: data => {
      setFile(data.files[0]);
    }
  });

  const context = useAppContext();
  const { user } = context;
  // console.log(vehicle_makes)
  const [showDetails, setShowDetails] = useState(false);
  const handleClose = () => setShowDetails(false);
  const handleShow = () => setShowDetails(true);
  const [showModal2, setShowModal2] = useState(false);
  const [commissions, setCommissions] = useState(null);
  const [insurerIDs, setInsurerIDs] = useState([]);

  const [APIData, setAPIData] = useState();
  const [filteredResults, setFilteredResults] = useState();
  const [searchInput, setSearchInput] = useState('');
  const { data, loading, error } = useQuery(LOAD_COMPANIES, {
    variables: {
      proxy_id: user?.id
    },
    onCompleted: data => {
      // console.log(data)
      setAPIData(data.proxy_insurance_company_links);
      setFilteredResults(data.proxy_insurance_company_links);
      // console.log(data.proxy_insurance_company_links.length)
    }
  });

  // const getNonLifeInsurers = () => {
  //   if (data) {
  //     let regex = convertWildcardStringToRegExp('*life')
  //     const nonLife = data.insurance_company.filter(insurer => {
  //       return regex.test(insurer.name)
  //     })
  //     return nonLife
  //   }
  // }

  const vehicleUses = [
    {
      id: 1,
      name: 'The carriage of goods or samples'
    },
    {
      id: 2,
      name: 'The carriage of passengers for hire or reward'
    },
    {
      id: 3,
      name: 'Use in connection with the motor trade'
    },
    {
      id: 4,
      name: 'Private use i.e. Use by yourself only on your own business'
    }
  ];

  const getVehicleUse = id => {
    let vehicleUse = vehicleUses.filter(usage => usage.id == Number(id));

    return vehicleUse[vehicleUse.length - 1]?.name;
  };

  // const downloadFile = fileID => {
  //   // console.log(fileID)
  //   // let extension = '.' + fileType.slice(-3);
  //   axios
  //     .get(`https://backend.staging.mypolicy.market/files/get_url?id=${fileID}`)
  //     .then(response => {
  //       // setLoading2(false)
  //       // let code = response.data.message.code
  //       // console.log(response.data.data)

  //       if (response.status == 200) {
  //         // console.log(response.data.data)
  //         // let myfile = new Blob([response.data.data], {type: fileType})
  //         // console.log(myfile)
  //         window.location.replace(response.data.data)
  //       }
  //     })
  //     .catch(function (error) {
  //       onError: error => {
  //         history.push({
  //           pathname: '/error-page',
  //           state: 'Failed to retrieve file for download'
  //         })
  //       }
  //     })
  // }

  // const getRequestType = id => {
  //   if (id === 1) {
  //     return 'Motor'
  //   } else if (id === 2) {
  //     return 'Home Owner'
  //   }
  // }

  // const [
  //   GetallAgentCompanies,
  //   { error: AgentError, loading: AgentLoading, data: AgentData }
  // ] = useLazyQuery(GET_ALL_COMPANIES_FOR_INTERMEDIARY, {
  //   onCompleted: data => {
  //     console.log(data)

  //     let u = []
  //     u.push(data.intermediary_insurance_company[0].insurance_company)

  //     setAPIData(u)
  //     setFilteredResults(u)
  //   }
  // })

  const [sendCustomerNotification] = useMutation(SEND_NOTIFICATION_CUSTOMER, {
    onCompleted: data => {
      SuccessNotification('Customer has been notified');
      console.log(data);
    },
    onError: error => {
      ErrorNotification('Failed to notify Customer');
      console.log(error);
    }
  });

  const [
    SubmitCustomerRequest,
    { error: CreateError, loading: CreateLoading }
  ] = useMutation(BROKER_CREATE_QUOTE, {
    onCompleted: data => {
      // console.log(data)
      submitquote()
    },
    onError: error => console.log(error)
  });

  const [getCommissions] = useLazyQuery(GET_PROXY_INSURER_COMMISSION, {
    onCompleted: data => {
      setCommissions(data.proxy_commissions);
      // console.log(data)
    },
    onError: error => {
      console.log(error);
    }
  });

  useEffect(() => {
    if (insurerIDs.length > 0) {
      getCommissions({
        variables: {
          proxyID: user?.id,
          _in: insurerIDs
        }
      });
    }
  }, [insurerIDs]);

  const confirmrequest = () => {
    if (formValues.length ===0){
      return ErrorNotification('Select Insusrer');
    }
    handleClose();
    Swal.fire({
      title: 'Are you sure you want to submit this quote?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes,accept',
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        // console.log('Sending quote')
        submit();
      }
    });
  };

  const [formValues, setFormValues] = useState([]);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];

    // console.log(newFormValues)

    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = (e, person) => {
    if (e.target.checked) {
      setFormValues([
        ...formValues,
        {
          name: person.insurance_company.name,
          premium_amount: '',

          description: [],
          id: person.insurance_company_id,
          Commission: 0
        }
      ]);

      setInsurerIDs([...insurerIDs, person.insurance_company_id]);
    } else {
      let newFormValues = [...formValues];
      const removeIndex = newFormValues.findIndex(
        item => item.name === person.name
      );
      newFormValues.splice(removeIndex, 1);
      setFormValues(newFormValues);
      let newIDs = [...insurerIDs];
      const removeInsurerIndex = newIDs.findIndex(
        id => id === person.insurance_company_id
      );
      newIDs.splice(removeInsurerIndex, 1);
      setInsurerIDs(newIDs);
    }
  };

  const submit = evt => {
    // console.log(rowdata.id)

    try {
      let quotes = [];
      console.log(formValues.length)

      for (var i = 0; i < formValues.length; i++) {
        let quote = {};
        // let data = []

        let proxyCommission = commissions?.filter(obj => {
          return obj.insurance_company_id === formValues[i].id;
        });

        // console.log(proxyCommission)

        quote.request_id = rowdata.request_id;
        quote.amount = formValues[i].premium_amount;
        quote.insurance_company_id = formValues[i].id;
        quote.proxy_id = user.id;
        quote.additional_options = formValues[i].additional_options;
        quote.customer_id = requestDetails.requests[0].customer_id;
        quote.created_by = user?.name;
        quote.broker_commission = proxyCommission[0]?.broker_commission;
        quote.sys_commission = user.system_commission;
        quotes.push(quote);
      }

      console.log(quotes)

      let res = SubmitCustomerRequest({
        variables: {
          objects: quotes
        }
      });
      console.log(res)
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (data) {
  //     setAPIData(data.proxy_insurance_company_links)
  //     setFilteredResults(data.proxy_insurance_company_links)
  //   }
  // }, [data])

  const sendSms = (number, message) => {
    var data = '';
    axios
      .post(
        `https://backend.staging.mypolicy.market/external/sms/?message=${message}&phone=${number}`
      )
      .then(response => {})
      .catch(function(error) {
        console.log(error);
      });
  };

  const submitquote = () => {
    // sendSms(
    //   '0' + rowdata.customer.phone_number,
    //   'You have received a quote for your request on mypolicy.market. Login to review your quote '
    // )
    SuccessNotification('Quote submitted successfully');
    navigate('/intermediary/requests/');
    sendCustomerNotification({
      variables: {
        id1: requestDetails.requests[0].customer_id,
        subject: 'MyPolicy.market Quotes Sent',
        messageShort: 'You have received some quotes for your requests',
        id: user?.id,
        username: user.name,
        name: user.name
      }
    });
  };

  const openSpecificModal = () => {
    setShowModal2(true);
  };

  const searchItems = searchValue => {
    setSearchInput(searchValue);
    if (searchInput !== '') {
      const filteredData = APIData.filter(item => {
        return Object.values(item)
          .join('')
          ?.toLowerCase()
          .includes(searchInput?.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(APIData);
    }
  };

  return (
    <>
      {requestDetails ? (
        <div style={{ padding: 30 }}>
          <Modal
            show={showModal2}
            onHide={() => setShowModal2(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard="false"
            centered
          >
            <Modal.Header>
              <Modal.Title
                style={{
                  fontWeight: 'bolder',
                  fontSize: '24px',
                  color: 'black'
                }}
                id="example-custom-modal-styling-title"
              >
                Send Quote(s)
              </Modal.Title>
              <div>
                <button
                  style={{
                    marginRight: 20,
                    backgroundColor: '#DF3F3F33',
                    color: '#DF3F3F',
                    width: '130px',
                    borderRadius: '18px',
                    fontSize: '18px'
                  }}
                  onClick={() => setShowModal2(false)}
                  type="button"
                  className="btn btn-danger"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  style={{
                    backgroundColor: '#2E79E9',
                    color: 'white',
                    width: '130px',
                    borderRadius: '18px',
                    fontSize: '18px'
                  }}
                  onClick={() => confirmrequest()}
                  type="button"
                  className="btn btn-primary"
                  aria-label="Close"
                >
                  Send
                </button>
              </div>
            </Modal.Header>
            <Modal.Body style={{}}>
              {CreateLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {' '}
                  <img src={customLoading} />{' '}
                </div>
              ) : (
                <div className="row" style={{ display: 'flex' }}>
                  <div
                    className="col-md-4"
                    style={{ background: 'white', padding: 10 }}
                  >
                    {loading ? (
                      <img src={customLoading} />
                    ) : (
                      <>
                        {APIData?.length == 0 ? (
                          <></>
                        ) : (
                          <>
                            <form>
                              <input
                                onChange={e => searchItems(e.target.value)}
                                style={{
                                  height: 50,
                                  borderRadius: 10,
                                  border: '2px solid #0E3E86'
                                }}
                                type="text"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Search insurance company here"
                              />

                              <div
                                style={{
                                  maxHeight: 500,
                                  overflowY: 'scroll',
                                  border: '2px solid #0E3E86',
                                  marginTop: 20,
                                  borderRadius: 10,
                                  padding: 10
                                }}
                              >
                                {/* {console.log(data)} */}
                                {searchInput.length > 1
                                  ? filteredResults.map((item, key) => {
                                      return (
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            marginTop: 20,
                                            backgroundColor: '#0E3E86',
                                            color: 'white',
                                            height: 72,
                                            borderRadius: 10,
                                            paddingTop: 20,
                                            paddingLeft: 20
                                          }}
                                          key={key}
                                        >
                                          <p key={key}>
                                            {item.insurance_company.name}
                                            {/* {console.log(item)} */}
                                          </p>

                                          <div class="form-check">
                                            <input
                                              onClick={e =>
                                                addFormFields(e, item)
                                              }
                                              class="form-check-input"
                                              type="checkbox"
                                              value=""
                                              id="flexCheckDefault"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })
                                  : APIData?.map((item, key) => {
                                      // console.log("Hello World")

                                      return (
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            marginTop: 20,
                                            backgroundColor: '#0E3E86',
                                            color: 'white',
                                            height: 72,
                                            borderRadius: 10,
                                            paddingTop: 20,
                                            paddingLeft: 20
                                          }}
                                          key={key}
                                        >
                                          <p key={key}>
                                            {item.insurance_company.name}
                                            {/* {console.log(item)} */}
                                          </p>
                                          <div key={key} class="form-check">
                                            <input
                                              key={key}
                                              onClick={e =>
                                                addFormFields(e, item)
                                              }
                                              class="form-check-input"
                                              type="checkbox"
                                              value=""
                                              id="flexCheckDefault"
                                            />
                                          </div>
                                          {/* <button className="btn btn-primary" onClick={()=>addFormFields(item)}>Add</button> */}
                                        </div>
                                      );
                                    })}
                              </div>

                              {/* {formValues.length> 0 ?  <button className='btn btn-primary' style={{width:"90%",position:"absolute", bottom:0,  right:0,margin:20}}>Send all quotes</button> : null}
                               */}
                            </form>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className="col-md-8"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 10,
                      maxHeight: 650,
                      overflowY: 'scroll'
                    }}
                  >
                    {formValues.length > 0 ? (
                      <>
                        {formValues.map((element, index) => (
                          <div
                            key={index}
                            style={{
                              border: '2px solid #0E3E86',
                              marginBottom: 10,
                              borderRadius: 10,
                              height: 150,
                              padding: 15
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: 700,
                                  fontSize: '18px',
                                  color: '#0E3E86'
                                }}
                              >
                                {element.name}
                              </p>
                              {/* <button onClick={() => removeFormFields(index)} style={{ height: 24, width: 24, backgroundColor: "#DF3F3F", color: "white", borderRadius: 5, border: "2px solid #DF3F3F", display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center" }}>x</button> */}
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingLeft: 15,
                                paddingRight: 15
                              }}
                              className="form-class"
                            >
                              <div class="form-row">
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                >
                                  <label
                                    for="inputEmail4"
                                    style={{
                                      fontWeight: '500',
                                      width: '100%',
                                      marginLeft: '5px'
                                    }}
                                  >
                                    Premium amount
                                    <span style={{ color: 'red' }}>*</span>
                                  </label>

                                  <input
                                    style={{
                                      position: 'relative',
                                      left: 5,
                                      height: '40px'
                                    }}
                                    type="text"
                                    placeholder="GHS 000"
                                    class="form-control"
                                    name="premium_amount"
                                    value={element.premium_amount || ''}
                                    onChange={e => handleChange(index, e)}
                                  />
                                </div>
                              </div>
                              <div class="form-row">
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                >
                                  <label
                                    for="inputEmail4"
                                    style={{
                                      fontWeight: '500',
                                      width: '100%',
                                      marginLeft: '5px'
                                    }}
                                  >
                                    Enter additional Info
                                    <span style={{ color: 'red' }}></span>
                                  </label>

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row'
                                    }}
                                  >
                                    <textarea
                                      style={{
                                        position: 'relative',
                                        height: '40px',
                                        marginRight: '15px',
                                        left: 5
                                      }}
                                      type="text"
                                      placeholder=""
                                      class="form-control"
                                      name="additional_options"
                                      value={element.additional_options}
                                      onChange={e => handleChange(index, e)}
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* <p style={{
                        fontWeight: 500,
                        fontSize: '15px',
                        color: "#0E3E86"
                      }}>Payable Commission(GHS)= {element.Commission}</p> */}
                            </div>

                            <hr />
                            {/* <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',

                          justifyContent: 'space-between'
                        }}
                      >
                        <Grid container spacing={2} wrap={'wrap'}>
                          <Grid
                            className={classes.item}
                            item
                            md={4}
                            sm={6}
                            xl={12}
                            xs={12}
                          >
                            <h6>Benefits of Premium</h6>

                            <CreatableSelect
                              isMulti
                              onChange={(e)=>handleChange2(index,e)}
                              options={benefits}
                            />

                            {benefits?.length < 1 ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <h4>No benefits yet</h4>
                              </div>
                            ) : (
                              null
                            )}
                          </Grid>
                        </Grid>
                      </div> */}
                          </div>
                        ))}
                      </>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          height: '400',
                          alignItems: 'start',
                          marginTop: 50
                          // border: 'solid'
                        }}
                      >
                        {APIData?.length == 0 ? (
                          <strong>
                            <Link to="/intermediary/settings">
                              Click here to add insurer account details
                            </Link>
                          </strong>
                        ) : (
                          <strong style={{ marginLeft: 100 }}>
                            No company selected
                          </strong>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            {requestDetails?.requests[0]?.home_details ? (
              <h2>Home Owner Insurance Details</h2>
            ) : (
              <h2>
                {/* {requestDetails?.requests[0]?.motor_details.motorInsuranceType}{' '} */}
                Motor Insurance Details
              </h2>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Button variant="outline-info" onClick={() => navigate(-1)}>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 30,
                    marginRight: 10
                  }}
                >
                  <BiArrowBack style={{ marginRight: 10 }} /> Go Back
                </span>
              </Button>{' '}
              {/* <Button
                variant='outline-success'
                style={{
                  marginLeft: 10
                }}
                onClick={e => {
                  e.preventDefault()
                  downloadFile(file.id)
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 30,
                    marginRight: 10
                  }}
                >
                  <BiDownArrow style={{ marginRight: 10 }} /> Download Road
                  Worthy Doc
                </span>
              </Button>{' '} */}
              <div style={{ marginLeft: 10 }}>
                <button
                  style={{ padding: 10, borderRadius: 5 }}
                  className="btn-primary"
                  onClick={() => openSpecificModal()}
                >
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 30
                    }}
                  >
                    Respond to Request
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              {/* <h6 style={{ color: "#0E3E86" }}>Requested by: {rowdata.requestby}</h6> */}
            </div>
            {requestDetails?.requests[0]?.home_details ? (
              <HomeOwner rowdata={requestDetails?.requests[0]} />
            ) : (
              <div
                style={
                  {
                    // overflowY: 'scroll',
                    // overflowX: 'hidden',
                    // maxHeight: 500
                  }
                }
              >
                <div
                  style={{
                    position: 'relative',
                    paddingTop: '1%',
                    width: '80%',
                    color: '#0E3E86',
                    width: 'inherit'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Table
                      bordered
                      style={{
                        width: '48%'
                      }}
                    >
                      <tbody>
                        <tr>
                          <td> Preferred Policy Cover</td>
                          <th>
                            {
                              requestDetails?.requests[0].motor_details
                                ?.motorInsuranceType
                            }
                          </th>
                        </tr>
                        <tr>
                          <td>Vehicle Make</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.vehicle_make
                            }
                          </th>
                        </tr>
                        <tr>
                          <td>Vehicle Model</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.model_id
                            }
                          </th>
                        </tr>
                        <tr>
                          <td>Vehicle Value</td>
                          <th>
                            {formatCurrency(
                              requestDetails?.requests[0]?.motor_details
                                ?.cost_price
                            )}
                          </th>
                        </tr>
                        <tr>
                          <td>Year of Registration</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.year_of_registration
                            }
                          </th>
                        </tr>
                        <tr>
                          <td>Do you want buy back excess?</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.buy_back_excess === true? "YES" : "NO"
                            }
                          </th>
                        </tr>
                        {/* <tr>
                          <td>Registration Number</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.registration_number
                            }
                          </th>
                        </tr> */}
                        {/* <tr>
                          <td>Chasis Number</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.chassis_number
                            }
                          </th>
                        </tr>
                        <tr>
                          <td>Engine Capacity</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.engine_capacity
                            }
                            L
                          </th>
                        </tr> */}
                      </tbody>
                    </Table>
                    {/* <Table
                      className='detail'
                      bordered
                      style={{
                        width: '48%',
                        marginBottom: 50
                      }}
                    >
                      <tbody>
                          <tr>
                            <td>Year of Registration</td>
                            <th>
                              {
                                requestDetails?.requests[0]?.motor_details
                                  ?.year_of_registration
                              }
                            </th>
                        </tr>
                        <tr>
                          <td>Car Mileage</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.mileage
                            }{' '}
                            miles
                          </th>
                        </tr>
                        <tr>
                          <td>Usage Type</td>
                          <th>
                            {requestDetails?.requests[0]?.motor_details
                              ?.usage_type
                              ? getVehicleUse(
                                  requestDetails?.requests[0]?.motor_details
                                    ?.usage_type
                                )
                              : 'N/A'}
                          </th>
                        </tr>
                        <tr>
                          <td>Proposed Inception Date</td>
                          <th>
                            {formatDate(
                              requestDetails?.requests[0]?.motor_details
                                ?.inception_date
                            )}
                          </th>
                        </tr>
                        <tr>
                          <td>Proposed Expiry date</td>
                          <th>
                            {formatDate(
                              requestDetails?.requests[0]?.motor_details
                                ?.expiry_date
                            )}
                          </th>
                        </tr>
                        <tr>
                          <td>Number of years without claim</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.no_claim_years
                            }
                          </th>
                        </tr>
                        <tr>
                          <td>Does Customer want buy back excess?</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.buy_back_excess
                            }{' '}
                          </th>
                        </tr>
                        {requestDetails?.requests[0]?.motor_details
                          .purchase_for_self === 'No' ? (
                          <>
                            <tr>
                              <td>Beneficiary Name</td>
                              <th>
                                {
                                  requestDetails?.requests[0]?.motor_details
                                    ?.beneficiary_name
                                }{' '}
                              </th>
                            </tr>
                            <tr>
                              <td>Beneficiary Email</td>
                              <th>
                                {
                                  requestDetails?.requests[0]?.motor_details
                                    ?.beneficiary_email
                                }{' '}
                              </th>
                            </tr>
                            <tr>
                              <td>Beneficiary Phone Number</td>
                              <th>
                                {
                                  requestDetails?.requests[0]?.motor_details
                                    ?.beneficiary_phone_number
                                }{' '}
                              </th>
                            </tr>
                            <tr>
                              <td>Beneficiary National ID</td>
                              <th>
                                {
                                  requestDetails?.requests[0]?.motor_details
                                    ?.beneficiary_id
                                }{' '}
                              </th>
                            </tr>
                          </>
                        ) : null}
                      </tbody>
                    </Table> */}
                  </div>
                </div>
              </div>
            )}

            <hr />

            <p style={{ float: 'right', fontWeight: 'bold', color: '#0E3E86' }}>
              Requested By: {rowdata?.customer_name}
            </p>
          </div>
        </div>
      ) : (
        <img
          src={customLoading}
          style={{ position: 'relative', marginTop: '20%', left: '45%' }}
        />
      )}
    </>
  );
};

export default BrokerRequestDetails;

export const HomeOwner = ({ rowdata }) => {
  const tabs = [];

  rowdata?.home_details.policy_options.map(option => {
    tabs.push(option.insurance_product_id);
  });

  if (rowdata?.home_details.purchase_for_self === 'No') {
    tabs.push('Beneficiary Info');
  }

  const [activeTab, setActiveTab] = React.useState(tabs[0]);

  const tableGrid = {
    display: 'flex',
    alignItems: 'center'
  };

  const table = {
    border: '1px solid #919090',
    width: '40%',
    height: '100px',
    marginTop: '10px',
    paddingLeft: '10px'
    // margin: "0 10px",
  };

  const th = {
    borderBottom: '1px solid #919090',
    borderLeft: '1px solid #919090',
    paddingLeft: '20px'
  };

  const tr = {
    borderBottom: '1px solid #919090'
  };

  const td = {
    borderLeft: '1px solid #919090',
    textAlign: 'left',
    paddingLeft: '20px'
  };

  const HeaderTitle = {
    fontSize: '1.5rem',
    textTransform: 'capitalize',
    color: '#1e40b7',
    fontWeight: 'bold'
  };

  const button = {
    backgroundColor: '#1e50ff',
    padding: '9px 9px',
    color: '#fff',
    fontWeight: '600',
    fontSize: '16px',
    borderRadius: '10px'
  };

  const footerText = {
    fontSize: '16px',
    textTransform: 'capitalize',
    color: '#919090'
  };

  const Header = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 15px'
  };

  const Tabs = {
    display: 'flex',
    alignItems: 'center'
  };

  const Tab = {
    padding: '8px 8px',
    textTransform: 'capitalize',
    color: '#00bcd4'
  };

  const ActiveTab = {
    color: '#000',
    fontWeight: '700',
    textTransform: 'capitalize',
    bordertop: '1px solid #ccc',
    padding: '8px 8px'
  };

  const line = {
    width: '100%',
    height: '1px',
    backgroundColor: '#ccc',
    margin: '10px 0'
  };

  return (
    <div style={{ marginTop: 10, width: 'inherit' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Table
          bordered
          style={{
            width: '48%'
          }}
        >
          <tbody>
            <tr>
              <td>Physical Address of Home</td>
              <th>{rowdata?.home_details?.physical_address}</th>
            </tr>
            <tr>
              <td>Digital Address of Home</td>
              <th>{rowdata?.home_details?.digital_address}</th>
            </tr>
          </tbody>
        </Table>
        <Table
          className="detail"
          bordered
          style={{
            width: '48%'
          }}
        >
          <tbody>
            <tr>
              <td>Proposed Policy Start Date</td>
              <th>{formatDate(rowdata?.home_details?.inception_date)}</th>
            </tr>
            <tr>
              <td>Proposed Policy End Date</td>
              <th>{formatDate(rowdata?.home_details?.expiry_date)}</th>
            </tr>
          </tbody>
        </Table>
      </div>
      <div style={{ padding: '0 1px', marginBottom: 15 }}>
        <div style={Tabs}>
          {tabs.map((item, index) => (
            <Button
              variant="outline-info"
              style={{ height: 50, marginRight: 5 }}
              key={index}
              onClick={() => setActiveTab(item)}
            >
              <p style={activeTab === item ? ActiveTab : Tab}>{item}</p>
            </Button>
          ))}
        </div>
      </div>

      <div style={tableGrid}>
        {activeTab === 'Rent' ? (
          <>
            <Table bordered>
              <tbody>
                <tr>
                  <td>Has Previous Rent Insurance</td>
                  <th>{rowdata?.home_details?.have_been_rent_insured}</th>
                </tr>

                <tr>
                  <td>Previous Rent Policy</td>
                  <th>{rowdata?.home_details?.prev_rent_policy}</th>
                </tr>
                <tr>
                  <td>Preferred Rent Assured Amount</td>
                  <th>
                    {formatCurrency(rowdata?.home_details?.rent_sum_assured)}
                  </th>
                </tr>
              </tbody>
            </Table>
          </>
        ) : activeTab === 'Building' ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Table bordered>
                <tbody>
                  <tr>
                    <td>Previous Building Insurance</td>
                    <th>{rowdata?.home_details?.prev_building_insurance}</th>
                  </tr>
                  <tr>
                    <td>Type of Building</td>
                    <th>{rowdata?.home_details?.building_type}</th>
                  </tr>
                  <tr>
                    <td>Building Color</td>
                    <th>{rowdata?.home_details?.building_color}</th>
                  </tr>
                  <tr>
                    <td>Construction Year of Building</td>
                    <th>{rowdata?.home_details?.construction_year}</th>
                  </tr>
                  <tr>
                    <td>Is the building residential?</td>
                    <th>{rowdata?.home_details?.is_residential}</th>
                  </tr>
                  <tr>
                    <td>Is business conducted in the building?</td>
                    <th>{rowdata?.home_details?.is_for_business}</th>
                  </tr>
                  <tr>
                    <td>
                      Does the building have interest from any financial institution?
                    </td>
                    <th>
                      {
                        rowdata?.home_details
                          ?.has_financial_institution_interest
                      }
                    </th>
                  </tr>
                </tbody>
              </Table>
              <Table bordered>
                <tbody>
                  <tr>
                    <td>Do you have any financial interest in the building?</td>
                    <th>{rowdata?.home_details?.has_financial_interest}</th>
                  </tr>
                  <tr>
                    <td>Materials used for building construction</td>
                    <th>
                      <ul class="list-group" style={{ marginTop: 10 }}>
                        {rowdata?.home_details?.building_materials?.map(
                          material => {
                            return <li class="list-group-item">{material}</li>;
                          }
                        )}
                      </ul>
                    </th>
                  </tr>
                  <tr>
                    <td>Materials used for roof construction</td>
                    <th>
                      <ul class="list-group" style={{ marginTop: 10 }}>
                        {rowdata?.home_details?.roofing_materials?.map(
                          material => {
                            return <li class="list-group-item">{material}</li>;
                          }
                        )}
                      </ul>
                    </th>
                  </tr>
                  <tr>
                    <td>Do you want to insure your walls?</td>
                    <th>{rowdata?.home_details?.insure_walls}</th>
                  </tr>
                  <tr>
                    <td>Value of wall</td>
                    <th>
                      {formatCurrency(
                        rowdata?.home_details?.wall_rebuilding_cost
                      )}
                    </th>
                  </tr>
                  <tr>
                    <td>Value of building</td>
                    <th>
                      {formatCurrency(rowdata?.home_details?.rebuidling_cost)}
                    </th>
                  </tr>
                </tbody>
              </Table>
            </div>
          </>
        ) : activeTab === 'Content' ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Table bordered>
                <tbody>
                  <tr>
                    <td>Has Previously insured content</td>
                    <th>
                      {rowdata?.home_details?.have_insured_content_before}
                    </th>
                  </tr>
                  <tr>
                    <td>Previous Content Insurance</td>
                    <th>{rowdata?.home_details?.prev_content_insurance}</th>
                  </tr>
                  <tr>
                    <td>Preferred Content Assured Amount</td>
                    <th>
                      {formatCurrency(
                        rowdata?.home_details?.content_sum_assured
                      )}
                    </th>
                  </tr>
                </tbody>
              </Table>
              <Table>
                <tbody>
                  <tr>
                    <td>Persons you want to insure</td>
                    <td>
                      <Table bordered>
                        <tr>
                          <th>Name</th>
                          <th>Sum Assured</th>
                          <th>Occupation</th>
                        </tr>
                        {rowdata?.home_details?.content_people?.map(
                          (val, key) => {
                            return (
                              <tr key={key} style={tr}>
                                <td style={td}>{val.name}</td>
                                <td style={td}>{val.sum_assured}</td>
                                <td style={td}>{val.occupation}</td>
                              </tr>
                            );
                          }
                        )}
                      </Table>
                    </td>
                  </tr>
                  <tr>
                    <td>Content you want to insure</td>
                    <td>
                      <Table bordered>
                        <tr>
                          <th>Item Name</th>
                          <th>Item Value</th>
                        </tr>
                        {rowdata?.home_details?.content_items?.map(
                          (val, key) => {
                            return (
                              <tr key={key} style={tr}>
                                <td style={td}>{val.name}</td>
                                <td style={td}>{val.value}</td>
                              </tr>
                            );
                          }
                        )}
                      </Table>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </>
        ) : activeTab === 'Personal Accident' ? (
          <>
            <Table bordered>
              <tbody>
                <tr>
                  <td>Has Previously insured for Personal Accident?</td>
                  <th>{rowdata?.home_details?.have_been_personally_insured}</th>
                </tr>
                <tr>
                  <td>Previous Content Insurance</td>
                  <th>
                    {rowdata?.home_details?.prev_personal_accident_policy}
                  </th>
                </tr>
                <tr>
                  <td>Preferred Personal Accident Assured Amount</td>
                  <th>
                    {formatCurrency(
                      rowdata?.home_details?.personal_accident_sum_assured
                    )}
                  </th>
                </tr>
              </tbody>
            </Table>
          </>
        ) : activeTab === 'Public Liability' ? (
          <>
            <Table bordered>
              <tbody>
                <tr>
                  <td>Has Previously insured for Public Liability?</td>
                  <th>
                    {rowdata?.home_details?.have_been_public_liability_insured}
                  </th>
                </tr>
                <tr>
                  <td>Previous Public Liability Insurance</td>
                  <th>{rowdata?.home_details?.prev_public_liability_policy}</th>
                </tr>
                <tr>
                  <td>Preferred Public Liability assured Amount</td>
                  <th>
                    {formatCurrency(
                      rowdata?.home_details?.public_liability_sum_assured
                    )}
                  </th>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <Table bordered>
              <tbody>
                <tr>
                  <td>Beneficiary Name</td>
                  <th>{rowdata?.home_details?.beneficiary_name} </th>
                </tr>
                <tr>
                  <td>Beneficiary Email</td>
                  <th>{rowdata?.home_details?.beneficiary_email} </th>
                </tr>
                <tr>
                  <td>Beneficiary Phone Number</td>
                  <th>{rowdata?.home_details?.beneficiary_phone_number} </th>
                </tr>
                <tr>
                  <td>Beneficiary National ID</td>
                  <th>{rowdata?.home_details?.beneficiary_id} </th>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </div>
  );
};
